import React, { useState, useEffect } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const Manage = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const userId1 = cookies.get("userId");
        const response = await fetch(`http://localhost:3001/posts/${userId1}`);
        if (!response.ok) {
          throw new Error("Failed to fetch posts");
        }
        const data = await response.json();
        setPosts(data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPosts();
  }, []);

  const handleGoBack = () => {
    // Go back one step
    window.history.back();
  };

  const handleDeletePost = async (postId) => {
    try {
      const response = await fetch(`http://localhost:3001/post/${postId}`, {
        method: "DELETE",
      });
      if (!response.ok) {
        throw new Error("Failed to delete post.");
      }
      // Remove the deleted post from the local state
      setPosts(posts.filter((post) => post.postId !== postId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="manage  w-full h-full bg-primary-100 font-kurdish_041">
      <div className="container h-full flex justify-start items-center flex-col p-8">
        <div className="flex  pb-4 justify-between items-center w-full">
          <div
            onClick={handleGoBack}
            className="backIcon  flex-[1] p-2 h-full hover:cursor-pointer center text-white text-3xl pt-4"
          >
            <IoIosArrowRoundBack />
          </div>
          <h1 className="flex-1 pt-2 text-2xl text-white font-kurdish_043 text-center">
            موڵکەکانم
          </h1>
          <h1 className="flex-1 pt-2 text-3xl text-white center "></h1>
        </div>
        <div className="posts w-full gap-4 flex flex-col">
          {posts.map((post) => (
            <div
              key={post._id}
              className="w-full flex flex-col border-2 border-white rounded-xl gap-2 p-2"
            >
              <div className="details  flex w-full rounded-xl ">
                <div className="image   flex-1 ">
                  <img className="rounded-l-xl" src={post.images[0]} alt="" />
                </div>
                <div className="title flex-[4] rounded-r-xl flex justify-center items-center text-xl text-primary-100 bg-white p-2">
                  {post.title}
                </div>
              </div>
              <div className="actionButtons flex w-full  gap-4 p-2 text-white">
                <div
                  onClick={() => handleDeletePost(post.postId)}
                  className="delete flex-1 bg-red-600 px-4 py-2 text-center rounded-md"
                >
                  سڕینەوە
                </div>
                <Link
                  to={`/edit/${post.postId}`}
                  className="delete flex-1 bg-green-600 px-4 py-2 text-center rounded-md"
                >
                  دەستکاریکردن
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
