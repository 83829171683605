import React from "react";
import { IoIosNotificationsOutline } from "react-icons/io";
import { SlHome } from "react-icons/sl";
import { CiLocationOn } from "react-icons/ci";
import { IoAddSharp } from "react-icons/io5";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
export const Navbar = () => {
  return (
    <div className=" flex mx-auto bottom-0 left-0 fixed p-4 w-full border-t-4 rounded-t-full border-x-2 border-primary-100 bg-white z-[9999]">
      <ul className="flex justify-around w-full items-center">
        <li>
          <Link to="/">
            <SlHome size={27} className="text-primary-200" />
          </Link>
        </li>
        <li>
          <Link to="/map">
            <CiLocationOn size={27} className="text-primary-200" />
          </Link>
        </li>
        <li>
          <Link to="/Upload">
            <IoAddSharp
              size={35}
              className="border-[3px] border-purple-600 rounded-xl text-primary-100"
            />
          </Link>
        </li>
        <li>
          <Link to="/notification">
            <IoIosNotificationsOutline size={27} className="text-primary-200" />
          </Link>
        </li>
        <li>
          <Link to="/profile">
            <CgProfile size={27} className="text-primary-200" />
          </Link>
        </li>
      </ul>
    </div>
  );
};
