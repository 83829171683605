import React, { useState } from "react";
import Banner from "../Elements/Banner";
import Posts from "../Posts/Posts";
import { Category } from "../Category/Category";
import { Companies } from "../Companies/Companies";

export const Home = () => {
  const [filter,setFilter]=useState("none")
  return (
    <div className="bg-white">
      <Banner />
      <Category setFilter={setFilter} />
      <Companies />
      <Posts filter={filter}/>
    </div>
  );
};
