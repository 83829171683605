import React from "react";

import { LuFilter } from "react-icons/lu";
export const Category = (props) => {
  return (
    <div className="  w-full  mt-2 mb-2 p-2  flex justify-around z-10 relative gap-4 flex-row-reverse">
      <div className="flex-1 flex  rounded-lg gap-2 justify-center items-center text-center min-w-16  bg-white px-2 py-2  text-primary-100 border-2 border-primary-100 font-kurdish_042">
        فلتەر
        <LuFilter />
      </div>
      <div className="flex-[4]  flex gap-2  overflow-x-scroll " dir="rtl">
        <div className="justify-center rounded-lg items-center text-center min-w-24 bg-primary-200 px-2 py-2 text-white hover:bg-primary-100 font-kurdish_042">
          خانوو
        </div>
        <div className="justify-center rounded-lg items-center text-center min-w-24 bg-primary-200 px-2 py-2 text-white hover:bg-primary-100 font-kurdish_042">
          شوقە
        </div>
        <div className="justify-center rounded-lg items-center text-center min-w-24 bg-primary-200 px-2 py-2 text-white hover:bg-primary-100 font-kurdish_042">
          دووکان
        </div>
        <div className="justify-center rounded-lg items-center text-center min-w-24 bg-primary-200 px-2 py-2 text-white hover:bg-primary-100 font-kurdish_042">
          ڤێلا
        </div>
        <div className="justify-center rounded-lg items-center text-center min-w-24 bg-primary-200 px-2 py-2 text-white hover:bg-primary-100 font-kurdish_042">
          زەوی
        </div>
      </div>
      {/* <div
        className="carousel-item  rounded-lg gap-2 justify-center items-center text-center min-w-16 flex-1 bg-white px-2 py-2  text-primary-100 border-2 border-primary-100 font-kurdish_042"
      >
        فلتەر
        <LuFilter />
      </div>
      <div
        className="carousel-item  rounded-lg justify-center items-center text-center min-w-16 flex-1 bg-primary-200 px-2 py-2 text-white hover:bg-primary-100 font-kurdish_042"
      >
        خانوو
      </div>
      <div
        className="carousel-item rounded-lg justify-center items-center text-center min-w-16 flex-1 bg-primary-200 px-2 py-2 text-white hover:bg-primary-100 font-kurdish_042"
      >
        شوقە
      </div>
      <div
        className="carousel-item rounded-lg justify-center items-center text-center min-w-16 flex-1 bg-primary-200 px-2 py-2 text-white hover:bg-primary-100 font-kurdish_042"
      >
        زەوی
      </div> */}
    </div>
  );
};
