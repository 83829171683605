import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { IoIosArrowRoundBack } from "react-icons/io";
import {
  IoChatboxEllipsesOutline,
  IoHeartOutline,
  IoHeart,
} from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { LuCalendarDays } from "react-icons/lu";
import { FaMapMarkedAlt } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { FaChartGantt } from "react-icons/fa6";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
const Post = () => {
  const { postId } = useParams();
  const [postData, setPostData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [fill, setFill] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!postId) {
          return; // If postId is not defined, exit early
        }
        // Fetch post data
        const postResponse = await fetch(`http://localhost:3001/post?postId=${postId}`);
        if (!postResponse.ok) {
          throw new Error("Failed to fetch post data");
        }
        const postData = await postResponse.json();
        // Fetch user data
        const userResponse = await fetch(`http://localhost:3001/user/${postData.data.postUserId}`);
        if (!userResponse.ok) {
          throw new Error("Failed to fetch user data");
        }
        const userData = await userResponse.json();
        setPostData(postData.data);
        setUserData(userData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [postId]);

  if (!postData || !userData) {
    return <div>Loading...</div>;
  }

  const handleheart = () => {
    setFill(!fill);
  };

  const handleGoBack = () => {
    // Go back one step
    window.history.back();
  };
  return (
    <div className="post w-full h-auto pb-32 flex flex-col  gap-4 ">
      {/* Top Bar */}
      <div className="topBar w-full h-24 p-4 flex justify-between items-center gap-4 absolute top-0 z-[9999]">
        {/* Back Button */}
        <div
          onClick={handleGoBack}
          className="backIcon  flex-[1] p-2 h-full hover:cursor-pointer"
        >
          <div className="cirlce rounded-full bg-primary-100 h-full aspect-square center text-white text-8xl">
            <IoIosArrowRoundBack />
          </div>
        </div>
        {/* Price */}
        <div className="price flex-[5] p-2 h-full">
          <div className="priceBack w-full h-full rounded-full bg-white center font-kurdish_043 text-primary-100 text-3xl gap-4 ">
            <span>{postData.priceType}</span>
            <span>{postData.price}</span>
          </div>
        </div>
        {/* Favorite Button */}
        <div className="Favorite flex-[1] p-2 h-full hover:cursor-pointer">
          <div className="cirlce rounded-full bg-primary-100 h-full aspect-square center text-white text-4xl">
            <div className="" onClick={handleheart}>
              {fill ? (
                <IoHeart style={{ color: "#ed4956" }} />
              ) : (
                <IoHeartOutline />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Images */}
      <div className="images w-full h-auto">
        <Swiper
          effect={"fade"}
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, EffectFade, Pagination, Navigation]}
          className="mySwiper"
          loop="true"
        >
          {Object.values(postData.images).map((imageUrl, index) => (
            <SwiperSlide key={index}>
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className="w-full h-[50vh] aspect-square "
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* title */}
      <div className="profil p-2 flex justify-center items-center  h-auto  text-center">
        <div className="title font-kurdish_043 font-bold text-2xl">
          {postData.title}
        </div>
      </div>
      {/* Profile */}
      <div className="profil p-4 flex justify-between items-center gap-4 h-28">
        {/* Action Buttons */}
        <div className="action flex justify-start items-center h-full flex-1 gap-4 text-3xl">
          <FiPhoneCall />
          <IoChatboxEllipsesOutline />
        </div>
        {/* Profile Details */}
        <Link
          to={`/user/${postData.postUserId}`}
          className="profileDetail h-full flex-[3] flex justify-end items-center gap-2"
        >
          <div className="name font-kurdish_041 text-primary-100 font-bold text-2xl text-right">
            {userData.name}
          </div>
          <div className="profilePicture rounded-full border-2 border-primary-100 h-full aspect-square overflow-hidden">
            <img src={userData.profileUrl} alt="" />
          </div>
        </Link>
      </div>

      {/* Type */}
      <div className="type p-4 flex justify-between items-center gap-4 h-16 font-bold">
        <div className="date flex-1 w-full h-full rounded-full center bg-primary-100 text-white text-center gap-1">
          <LuCalendarDays />
          {postData.date}
        </div>
        <div className="sellOrRent flex-1 w-full h-full rounded-full center bg-primary-100 text-white text-center gap-1">
          {postData.sellOrRent}
        </div>
        <div className="type flex-1 w-full h-full rounded-full center bg-primary-100 text-white text-center gap-1">
          {postData.type}
        </div>
      </div>

      {/* About */}
      <div className="about text-right p-4 font-kurdish_043 text-xl">
        <p className="border-2 border-dashed border-primary-100 p-2">
          {postData.about}
        </p>
      </div>

      {/* Address */}
      <div className="adress p-4 h-20 flex justify-end items-center gap-2 text-xl font-kurdish_041">
        {postData.address}
        <div className="cirlce rounded-full bg-primary-100 h-full aspect-square center text-white text-4xl">
          <CiLocationOn />
        </div>
      </div>

      {/* Futures */}
      <div className="futuresTitle p-4 h-20 flex justify-end items-center gap-2 text-xl font-kurdish_041 text-primary-100 font-bold">
        تایبەتمەندی
        <div className="cirlce rounded-full bg-primary-100 h-full aspect-square center text-white text-2xl">
          <FaChartGantt />
        </div>
      </div>
      <div className="Futures carousel carousel-center overflow-y-hidden flex-row-reverse h-16 p-4 w-full gap-4 px-2">
        {Object.values(postData.futures).map((future, index) => (
          <div
            key={index}
            className="carousel-item p-2 min-w-24 max-w-32 flex-1 w-full h-full rounded-full center bg-primary-100 text-white text-center gap-1"
          >
            {future}
          </div>
        ))}
      </div>

      {/* Map */}
      <div className="mapTitle p-4 h-20 flex justify-end items-center gap-2 text-xl font-kurdish_041 text-primary-100 font-bold">
        ماپ
        <div className="cirlce rounded-full bg-primary-100 h-full aspect-square center text-white text-2xl">
          <FaMapMarkedAlt />
        </div>
      </div>
      <div className="map w-full aspect-[16/9] p-4">
        <MapContainer
          center={postData.position}
          zoom={17}
          scrollWheelZoom={true}
          className="w-full h-full"
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={postData.position} />
        </MapContainer>
      </div>
    </div>
  );
};

export default Post;
