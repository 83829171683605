import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";

// import {
//   IoChatboxEllipsesOutline,
//   IoHeartOutline,
//   IoHeart,
// } from "react-icons/io5";
// import { FiPhoneCall } from "react-icons/fi";
// import Crown from "../../assets/images/crown.png";
export const Companies = () => {
  const imageData = [
    {
      src: "https://i.pinimg.com/564x/96/9e/29/969e29466c4040aa181f7d80e6cac331.jpg",
      alt: "Home",
      location: "سلێمانی ",
      name: "نوسینگەی ئالان",
    },
    {
      src: "https://i.pinimg.com/564x/0f/8b/a5/0f8ba5b8d78ed7c43ababd190aafb282.jpg",
      alt: "Home",
      price: "850,000$",
      location: "سلێمانی ",
      name: "نوسینگەی دیدار",
    },
    {
      src: "https://i.pinimg.com/564x/88/83/c9/8883c93ebe6fc86aed2ed72f117b306d.jpg",
      alt: "Home",
      price: "950,000$",
      location: "هەولێر ",
      name: "نوسینگەی حاکم",
    },
    {
      src: "https://i.pinimg.com/564x/56/10/19/5610195ae334cf5f7f1f4e6893eb4d4e.jpg",
      alt: "Home",
      price: "650,000$",
      location: "ڕانیە ",
      name: "نوسینگەی هەنگاو",
    },
    {
      src: "https://i.pinimg.com/564x/9f/e3/01/9fe30127806487440ab6a36ee165d58e.jpg",
      alt: "Home",
      price: "550,000$",
      location: "کەرکووک  ",
      name: "نوسینگەی محمد",
    },
  ];

  const [fill, setFill] = useState(Array(imageData.length).fill(false));

  const handleheart = (index) => {
    const updateFill = [...fill];
    updateFill[index] = !updateFill[index];
    setFill(updateFill);
  };
  return (
    <div className="companies">
      <div className="titleAndSeeMore w-full h-auto py-1 px-4  flex gap-4 justify-between items-center">
        <div className="seeMore  flex-[2] text-left font-kurdish_041 text-primary-100 text-sm">
          {` بینینی هەمووی `}
        </div>
        <div className="space  flex-[1]"></div>
        <div className="title px-2  flex-[2] text-right font-kurdish_041 font-bold text-primary-100">
          {`: نوسینگەکان`}
        </div>
      </div>

      <div
        className="carousel  carousel-center mt-2 w-full gap-4 p-4 "
        dir="rtl"
      >
        {imageData.map((image, index) => (
          <Link to={"/"} className="carousel-item" key={index}>
            <div className="card relative shadow-lg mb-6  pb-2 w-48 ">
              <div className="image relative">
                <img
                  src={image.src}
                  className=" w-full aspect-square rounded-tr-lg rounded-tl-lg"
                  alt={image.alt}
                />
                <p className="text-sm flex font-bold text-white font-kurdish_041 px-2 py-1 center text-center absolute top-0 bg-primary-100 right-0 rounded-tr-lg rounded-bl-lg ">
                  <CiLocationOn className="text-md" />
                  {image.location}
                </p>
              </div>

              <div className="title center  gap-4 center  p-2">
                <p className="center font-kurdish_043 text-md">{image.name}</p>
                <img
                  src="https://i.ibb.co/NshSFb5/pngwing-com.png"
                  className="  h-6 w-6 "
                  alt="h"
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
