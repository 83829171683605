// Import necessary icons
import { MdOutlineAccountCircle } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { LuImagePlus } from "react-icons/lu";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdTextSnippet } from "react-icons/md";
export const UserForm = (props) => {
  // Initialize state variables
  const cookies = new Cookies(null, { path: "/" });
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileUrl, setProfileUrl] = useState("");
  const [bio, setBio] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [imageFile, setImageFile] = useState(null); // New state variable to store the selected image file
  const userId = cookies.get("userId");
  const [errors, setErrors] = useState({});
  useEffect(() => {
    // Fetch user data when the component mounts
    const fetchUserData = async () => {
      try {
        const response = await fetch(`http://localhost:3001/user/${userId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data.");
        }
        const userData = await response.json();
        // Pre-fill input fields if user data is available
        if (userData) {
          setName(userData.data.name || "");
          setPhoneNumber(userData.data.phoneNumber || "");
          setProfileUrl(userData.data.profileUrl || "");
          setBio(userData.data.bio || "");
        }
      } catch (error) {
        console.error(error);
        // Handle error, such as showing an error message or redirecting to an error page
      }
    };

    fetchUserData();
  }, [userId]);

  // Function to handle image upload
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setImageFile(file); // Store the selected image file in state

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await fetch(
        "https://api.imgbb.com/1/upload?key=a494e9a0212cebf741b10bcb8f803a3f",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload image.");
      }

      const data = await response.json();
      setProfileUrl(data.data.display_url);
    } catch (error) {
      console.error(error);
      setError("An error occurred while uploading the image.");
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = {};

    try {
      if (!name) {
        errors.name = "تکایە ئەم بۆشاییە پڕ بکەوە";
      }
      if (bio && bio.length > 50) {
        errors.bio = "تکایە کەمتر لە ٥٠ کاراکتەر بەکاربهێنە";
      }
      if (!phoneNumber) {
        errors.phoneNumber = "تکایە ئەم بۆشاییە پڕ بکەوە";
      }
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
      const response = await fetch("http://localhost:3001/updateUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name.toLowerCase(),
          phoneNumber: phoneNumber.toLowerCase(),
          profileUrl: profileUrl,
          userId: userId,
          bio: bio,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update user.");
      }
      window.location.href = "/";
    } catch (error) {
      console.error(error);
      setError("An error occurred while updating user.");
    } finally {
      setLoading(false);
    }
  };
  const handleGoBack = () => {
    // Go back one step
    window.history.back();
  };

  return (
    <div className="w-full h-full bg-primary-300 text-white">
      <div className="container h-full flex justify-start   items-center flex-col p-8">
        <div className="flex justify-between items-center w-full">
          <div
            onClick={handleGoBack}
            className="backIcon  flex-[1] p-2 h-full hover:cursor-pointer center text-white text-3xl pt-4"
          >
            <IoIosArrowRoundBack />
          </div>
          <h1 className="flex-1 pt-4 text-2xl text-white font-kurdish_043 text-center">
            ئەکاونتەکەم
          </h1>
          <h1 className="flex-1 pt-4 text-3xl text-white center "></h1>
        </div>
        <div className=" w-full h-full flex-[2] flex flex-col justify-start items-center py-16 gap-8 ">
          <form onSubmit={handleSubmit} className="flex w-full flex-col gap-8">
            <div className="label w-full flex justify-center items-center text-xl  font-kurdish_041 p-2">{`ناوی بەکارهێنەر`}</div>
            {errors.name ? (
              <div className="titleLabel text-center text-xs text-red-600 ">
                {errors.name}
              </div>
            ) : (
              <></>
            )}
            <div className="username w-full flex justify-center items-center -mt-4 mr-4">
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                className="w-full h-full bg-transparent placeholder-white -mr-9 text-center p-4 border-2 rounded-full"
                placeholder="ناوی سیانی"
              />
              <MdOutlineAccountCircle className="text-4xl -ml-4 text-white" />
            </div>
            <div className="label w-full flex justify-center items-center text-xl  font-kurdish_041 p-2">{`بایۆ `}</div>
            {errors.bio ? (
              <div className="titleLabel  text-xs text-red-600 ">
                {errors.bio}
              </div>
            ) : (
              <></>
            )}
            <div className="username w-full flex justify-center items-center -mt-4 mr-4">
              <input
                onChange={(e) => setBio(e.target.value)}
                value={bio}
                type="text"
                className="w-full h-full bg-transparent placeholder-white -mr-9 text-center p-4 border-2 rounded-full"
                placeholder="ناوی سیانی"
              />
              <MdTextSnippet className="text-4xl -ml-4 text-white" />
            </div>
            <div className="label w-full flex justify-center items-center text-xl  font-kurdish_041 p-2">{`ژمارە مۆبایل`}</div>
            {errors.phoneNumber ? (
              <div className="titleLabel text-xs text-red-600 ">
                {errors.phoneNumber}
              </div>
            ) : (
              <></>
            )}
            <div className="email w-full flex justify-center items-center -mt-4 mr-4">
              <input
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                type="text"
                className="w-full h-full bg-transparent placeholder-white -mr-9 text-center p-4 border-2 rounded-full"
                placeholder="ژمارە موبایل"
              />
              <BsTelephone className="text-4xl -ml-4 text-white" />
            </div>
            <div className="label w-full flex justify-center items-center text-xl  font-kurdish_041 p-2">{`وێنەی بەکارهێنەر`}</div>

            <div className="profileUrl flex flex-col w-full  gap-16 justify-center items-center -mt-4 mr-4">
              <input
                type="file"
                onChange={handleImageUpload}
                accept="image/*"
                className="hidden"
                id="imageUpload"
              />

              {/* Preview of the selected image */}
              {imageFile && (
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="Preview"
                  className="w-32 h-32 object-cover rounded-full shadow-lg mt-4"
                />
              )}

              {/* If there's no selected image but there's a profileUrl, display the already selected image */}
              {profileUrl && !imageFile && (
                <img
                  src={profileUrl}
                  alt="Preview"
                  className="w-32 h-32 object-cover rounded-full shadow-lg mt-4"
                />
              )}

              {/* Image upload icon */}
              <label
                htmlFor="imageUpload"
                className="  cursor-pointer bg-primary-500 -mt-28 rounded-lg p-2"
              >
                <LuImagePlus className="text-4xl text-white" />
              </label>
            </div>

            <div className="username flex justify-center items-center">
              <button
                type="submit"
                className="px-8 py-4 bg-white text-primary-100 text-center border-2 rounded-xl text-3xl"
                disabled={loading}
              >
                {loading ? "چاوەڕێ بکە !" : "دڵنیاکردنەوە"}
              </button>
            </div>
          </form>

          {error && (
            <div className="error-message text-red-500 text-center mt-2">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
