import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import {
  IoChatboxEllipsesOutline,
  IoHeartOutline,
  IoHeart,
} from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import Crown from "../../assets/images/crown.png";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await fetch(
        "http://localhost:3001/posts"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch posts");
      }
      const data = await response.json();
      setPosts(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [fill, setFill] = useState([]);

  const handleheart = (index) => {
    const updateFill = [...fill];
    updateFill[index] = !updateFill[index];
    setFill(updateFill);
  };

  return (
    <div className="carousel carousel-vertical carousel-center flex justify-start items-center flex-col mt-2 w-full gap-4 p-4 pb-32">
      {posts.map((post, index) => (
        <div className="carousel-item min-w-full" key={index}>
          <div className="card min-w-full relative shadow-lg mb-6 pb-2">
            <Link to={`/post/${post.postId}`} className="image relative">
              <img
                src={Crown}
                className="absolute left-3 h-8 w-8 bg-[#e1b600] rounded-b-lg"
                alt="h"
              />
              <p className="text-sm flex font-bold text-white font-kurdish_041 px-2 py-1 center text-center absolute top-0 bg-primary-100 right-0 rounded-tr-lg rounded-bl-lg ">
                {post.address}
                <CiLocationOn className="text-md" />
              </p>
              <img
                src={post.images[0]} // Assuming the first image is the main image
                className="w-full aspect-square rounded-tr-lg rounded-tl-lg"
                alt={post.title}
              />
            </Link>
            <Link to={`/post/${post.postId}`} className="title center p-2">
              <p className="center font-kurdish_043 text-2xl"> {post.title} </p>
            </Link>
            <div className="flex p-2">
              <div className="flex-[1] flex flex-col text-center bg-primary-100 text-white rounded-md">
                <h6 className="font-semibold font-kurdish_042 px-4 py-2 center gap-2">
                  <span>{post.priceType}</span>
                  <span>{post.price}</span>{" "}
                </h6>
              </div>
              <div className="flex-[1] flex justify-center items-center text-primary-100 text-3xl gap-4">
                <IoChatboxEllipsesOutline />
                <div className="" onClick={() => handleheart(index)}>
                  {fill[index] ? (
                    <IoHeart style={{ color: "#ed4956" }} />
                  ) : (
                    <IoHeartOutline />
                  )}
                </div>
                <Link className="phone">
                <FiPhoneCall />

                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Posts;
